import React from "react"

import styled from "styled-components"

import sectionIcon from "../images/section-icon.png"

const SectionBoxS = styled.div`
  display: flex;
  img {
    margin-top: 3px;
    margin-right: 10px;
    width: auto;
    height: 100%;
  }
`

const SectionBox = ({ children }) => (
  <SectionBoxS>
    <img src={sectionIcon} />
    {children}
  </SectionBoxS>
)

export default SectionBox
