import bgSvg1 from "../../images/bg home 01.svg"
import styled from "styled-components"

const SvgBg1 = styled.div`
  background-image: url(${bgSvg1});
  background-repeat: no-repeat;
  background-size: 100%;
`

export default SvgBg1
