import React from "react"

import styled from "styled-components"

import sectionIcon from "../images/section-icon.png"

const SectionBoxS = styled.div`
  display: flex;
  margin-left: 2.1em;
`

const SectionBox2 = ({ children }) => <SectionBoxS>{children}</SectionBoxS>

export default SectionBox2
