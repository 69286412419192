/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

import styled from "styled-components"

import footerBg from "../images/oblakoder-footer.jpg"
import { ParallaxProvider } from "react-scroll-parallax"

const Main = styled.div`
  margin: 0 auto;
`

const Footer = styled.div`
  background-image: url(${footerBg});
  padding: 0em 0;
  text-align: center;
  div {
    color: #fff;
    text-align: center;
  }
`

const Coppy = styled.div`
  background-color: #372577;
  padding: 10px 0;
  div {
    font-size: 0.8em;
    color: #776f9d;
    text-align: center;
  }
  a {
    color: #776f9d;
    text-decoration: none;
  }
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <ParallaxProvider>
        <Header siteTitle={data.site.siteMetadata.title} />
        <Main>
          <div>{children}</div>
        </Main>
        <Footer></Footer>
        <Coppy>
          <div>
            Copyright © 2019 Nephele All rights reserved. Powered by{" "}
            <a target="_blank" href="https://digitalartsagency.com">
              Digital Arts
            </a>
          </div>
        </Coppy>
      </ParallaxProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
