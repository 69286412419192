import styled from "styled-components"

const ImageBox1 = styled.div`
  text-align: right;
  position: relative;
  margin-top: -2%;
  .res-img {
    display: none;
    padding-top: 3em;
  }

  @media (max-width: 768px) {
    .res-img {
      display: block;
    }
    .png-img {
      display: none;
    }
  }
`
export default ImageBox1
