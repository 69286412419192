//import { Link } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import PropTypes from "prop-types"
import React from "react"

import styled from "styled-components"

import logo from "../images/logo.png"

const HeaderStyle = styled.div`
  background-color: transparent;
  padding: 1.2em 0;
  div {
    max-width: 80%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    ul {
      list-style: none;
      margin: 0;
      padding-left: 0;
      z-index: 9999;
      background-color: #fff;
      li {
        margin: 10px 0;
        &:hover {
          color: #000;
          border-bottom: unset;
        }
      }
    }

    li {
      display: block;
      position: relative;
      text-decoration: none;
      transition-duration: 0.5s;
      margin-bottom: 0;
      margin-right: 2em;
      color: #4d4e4e;
      text-decoration: none;
      margin-right: 2em;
      font-family: "Blinker", sans-serif;
      font-weight: 600;
      transition: 0.3s all;
      z-index: 999;
      &:hover {
        color: #000;
        border-bottom: 1px solid;
      }
    }

    li:hover {
      cursor: pointer;
    }

    li {
      ul {
        visibility: hidden;
        opacity: 0;
        width: 14rem;
        position: absolute;
        transition: all 0.5s ease;
        margin-top: 0;
        padding: 10px;
        left: -0.5em;
        top: 1.2em;
        display: none;
        z-index: 200;
        &:hover {
          visibility: visible;
          opacity: 1;
          display: block;
        }
      }
    }

    li:hover > ul {
      visibility: visible;
      opacity: 1;
      display: block;
    }

    ul:hover {
      visibility: visible;
      opacity: 1;
      display: block;
    }
  }
  a {
    color: #4d4e4e;
    text-decoration: none;
    margin-right: 2em;
    font-family: "Blinker", sans-serif;
    font-weight: 600;
    transition: 0.3s all;
    &:hover {
      color: #000;
    }
    img {
      margin-bottom: 0;
    }
  }
`

const Header = ({ siteTitle }) => (
  <HeaderStyle>
    <div>
      <AniLink fade to="/">
        <img src={logo} />
      </AniLink>
      <li>
        Solutions
        <ul>
          <li>
            <AniLink fade to="/migrate-to-aws">
              Migrate to AWS
            </AniLink>
          </li>
          <li>
            <AniLink fade to="/security-in-the-cloud">
              AWS Security in the cloud
            </AniLink>
          </li>
          <li>
            <AniLink fade to="/aws-well-architected">
              AWS Well-Architected
            </AniLink>
          </li>
          <li>
            <AniLink fade to="/serverless-aws">
              Serverless AWS
            </AniLink>
          </li>
          <li>
            <AniLink fade to="/aws-for-developers">
              AWS for Developers
            </AniLink>
          </li>
        </ul>
      </li>
      <AniLink fade to="/contact">
        Contact
      </AniLink>
    </div>
  </HeaderStyle>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
