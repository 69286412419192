import styled from "styled-components"

const GridStyled1 = styled.div`
  display: grid;
  grid-template-columns: 30% 70%;
  align-items: flex-start;
  padding: 3em 0;
  max-width: 80%;
  margin: 0 auto;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    height: auto;
  }
  span {
    font-weight: 400;
  }
  h3 {
    color: #1a73e8;
  }
`
export default GridStyled1
